import React from 'react';

import config from '../../config';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import avatar from '../assets/img/avatar.jpg';
import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic5 from '../assets/images/pic05.jpg';
import logoTQ from '../assets/images/logo_tq.png';
import logoMyteam from '../assets/images/logo_myteam.png';
import logoWeDive from '../assets/images/logo_wedive.png';
import logoScubaSociety from '../assets/images/logo_scubasociety.png';

const IndexPage = () => (
    <Layout>
        <section id="banner">
            <div className="inner">
                <div>
                    <img src={avatar} alt="It's me as lego scuba diver" className="avatar"/>
                </div>
                <h2>{config.heading}</h2>
                <p>{config.subHeading}</p>
            </div>
            <Scroll type="id" element="one">
                <a href="#one" className="more">
                    descent
                </a>
            </Scroll>
        </section>

        <section id="one" className="wrapper style1 special">
            <div className="inner">
                <header className="major">
                    <h2>
                        Helping companies to transform their ideas into digital solutions – from web to mobile
                    </h2>
                    <p>
                        Welcome to the digital odyssey of an innovator, leader, and underwater enthusiast. My journey
                        began with a fervent interest in software development, where I transformed my early passion for
                        PHP and keen sense for UX/UI design into a thriving career, and eventually, a groundbreaking
                        company. In 2003, I co-founded TEQneers, marking the start of an exciting voyage in the tech
                        world. Over the next 13 years, I spearheaded the SoFi software development team, steering it to
                        become a market leader in EHS&S management solutions. My approach has always been about more
                        than coding; it's about reimagining business ideas and processes for the digital age.
                    </p>
                    <p>
                        Beyond the realms of technology, my life is enriched by the joys and challenges of being a
                        father to a vibrant family of five. Inspired by my children's love for football, I conceived the
                        idea for the <a href="https://www.myteam-app.de" target="_blank">myteam App</a>, a testament to
                        my commitment to fostering community spirit.
                    </p>
                    <p>
                        In 2019, I've plunged into the serene world of scuba diving. For me, diving is not just a
                        sport; it's a sanctuary of peace and a profound way to connect with nature. Embracing new
                        challenges and an unquenchable thirst for learning, I'm currently honing my skills as a
                        Sidemount Tech Diver.
                    </p>
                    <p>
                        Excitingly, my passion for the underwater world and digital innovation have converged in my
                        latest endeavor – founding "<a href="https://www.scuba-society.com/" target="_blank">The Scuba
                        Society</a>". This project is envisioned as a digital hub, destined to become the go-to source
                        for all scuba diving news and information. It's a platform where the vibrant scuba community can
                        come together, share experiences, and stay abreast of the latest developments in this
                        exhilarating sport. Join me in this exciting new chapter as we dive deeper into the digital and
                        aquatic worlds!
                    </p>
                </header>
                <ul className="logos">
                    <li>
                        <a href="https://www.teqneers.de/" target="_blank">
                            <img src={logoTQ} alt="Logo TEQneers"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.scuba-society.com/" target="_blank">
                            <img src={logoScubaSociety} alt="Logo The Scuba Society"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.wedive.pt/" target="_blank">
                            <img src={logoWeDive} alt="Logo WeDive Lagos"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.myteam-app.de/" target="_blank">
                            <img src={logoMyteam} alt="Logo myteam App"/>
                        </a>
                    </li>
                </ul>
            </div>
        </section>

        <section id="two" className="wrapper alt style2">
            <section className="spotlight">
                <div className="image">
                    <img src={pic1} alt=""/>
                </div>
                <div className="content">
                    <h2>
                        CTO and co-founder, TEQneers
                    </h2>
                    <p>
                        TEQneers stands for digital progress. Innovative and individual web development and mobile apps
                        are our strengths. With more than 20 years of experience in various industries and projects, we
                        validate your ideas and processes. Together with our customers we develop their needs further
                        and find the optimal digital solution.
                    </p>
                </div>
            </section>
            <section className="spotlight">
                <div className="image">
                    <img src={pic5} alt=""/>
                </div>
                <div className="content">
                    <h2>
                        The Scuba Society
                    </h2>
                    <p>
                        In late 2023 I founded The Scuba Society and started this new project - Your ultimate diving
                        hub! Discover, connect, and be inspired by the best of the underwater world. <a
                        href="https://www.scuba-society.com/" target="_blank">Join</a> us in exploring a sea of
                        adventure!
                    </p>
                </div>
            </section>
            <section className="spotlight">
                <div className="image">
                    <img src={pic2} alt=""/>
                </div>
                <div className="content">
                    <h2>
                        SCUBA FOR LIFE
                    </h2>
                    <p>
                        Since summer 2019 I discovered sucba diving as a new love. With great passion and thanks to the
                        great support of the diving school <a href="https://www.wedive.pt" tagert="_blank">WeDive</a> in
                        Lagos, Portugal, I was able to finish many other specialties in a very short time and have been
                        verified as Master and Extended Range Diver by SSI. Trained by Vas Proud aka <a
                        href="https://www.diverbydesign.com" tagert="_blank">Diver by Design</a> from <a
                        href="https://www.sidemounting.com/affiliate/7/" tagert="_blank">sidemounting.com</a>, I have
                        also reached out to become a better diver (10% coupon code: yzsj-hxhx-jyff).
                    </p>
                    <p>Check out some of my <a href="/Impressions" target="_blank">scuba diving impressions</a>.</p>
                </div>
            </section>
            <section className="spotlight">
                <div className="image">
                    <img src={pic3} alt=""/>
                </div>
                <div className="content">
                    <h2>
                        Voluntary commitment
                    </h2>
                    <p>
                        With the <a href="https://www.myteam-app.de/" target="_blank">myteam App</a>, TEQneers has
                        created an app for team organization in sports. Together with TEQneers we make our know-how and
                        experience in the digitization of business processes available to make the app voluntarily free
                        of charge and without any ads. We are proud to help the community.
                    </p>
                    <p>
                        <strong>Respect, Love, Save – Sharks <span className="icon solid fa-fish">&#160;</span></strong><br/>
                        As a member of the <a href="https://www.sharkproject.org/" target="_blank">SHARPROJECT
                        e.V.</a>, I support their mission to protect these valuable animals, in education and training
                        and in cooperation with other NGOs.
                    </p>
                </div>
            </section>
        </section>

        <section id="cta" className="wrapper style1">
            <div className="inner">
                <header>
                    <h2>Thanks for visiting me!</h2>
                    <p>
                        When you have reached this point, you have most likely reached the ground and can slowly ascent
                        again.
                    </p>
                </header>
                <ul className="actions stacked">
                    <li>
                        <a href="/#" className="button fit">
                            Ascent
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </Layout>
);

export default IndexPage;
